import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const wrapper = {
  background: "rgb(81, 25, 122)",
  color: "white",
  textAlign: "center",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}

const h1 = {
  fontSize: "36px",
  fontFamily: "ZonaProBlack",
  margin: "30px 0",
}

const p = {
  maxWidth: "400px",
  fontSize: "18px",
  lineHeight: 1.4,
}

const NotFoundPage = () => {
  const { logoImage } = useStaticQuery(
    graphql`
      query {
        logoImage: file(relativePath: { eq: "acai-bello-fruto-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <SEO title="Mensagem enviada" />
      <div style={wrapper}>
        <div style={{ width: "160px" }}>
          <Img fluid={logoImage.childImageSharp.fluid} />
        </div>
        <h1 style={h1}>Mensagem enviada!</h1>
        <p style={p}>
          Sua mensagem foi enviada! Em breve nossa equipe entrará em contato.
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
